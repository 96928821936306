<template>
  <editor
    :id="id" :content="content" :height='300'
    :show-module-name='showModuleName'
    @change='updateData'
    ref='editor'
  ></editor>
</template>

<script>
import $ from 'jquery'
import VueHtml5Editor from '@/resources/js/vue-html5-editor'
import VueHtml5EditorConfig from '@/resources/js/vuehtml5-config'

const editor = new VueHtml5Editor(VueHtml5EditorConfig.default);
export default {
  name: 'VueH5Editor',
  props: {
    value: {
      type: String,
      default: '',
    }
  },
  components: {
    editor,
  },
  data() {
    return {
      id: new Date().getTime(),
      showModuleName: false,
    }
  },
  computed: {
    content() {
      let {value} = this;
      if (value) {
        return value;
      }
      return '';
    }
  },
  watch: {},
  methods: {
    updateData: function (data) {
      this.$emit('input', data);
    },
    customerToolbar() {
      let {id} = this;
      $(`#${id}`).find('.toolbar > ul').append('<li style=" color: #9c9c9c;">可通过微信ALT+A截图快捷键粘贴到本编辑器  （shift）滚动图片可缩放</li>')
    },
  },
  beforeMount() {
    $(document).ready(() => {
      let shiftDown = false
      const shiftKey = 16
      const cmdKey = 91

      $(document).keydown(function (e) {
        if (e.keyCode === shiftKey || e.keyCode === cmdKey) shiftDown = true
      }).keyup(function (e) {
        if (e.keyCode === shiftKey || e.keyCode === cmdKey) shiftDown = false
      })

      const body = $('body')
      const ball_num = 0
      const shift_ball_num = 30
      if (navigator.userAgent.indexOf('Firefox') >= 0) {
        body.on('DOMMouseScroll', '.vue-html5-editor > .content img', function (e) {
          const turn_num = (shiftDown) ? shift_ball_num : ball_num
          $(this).width((e.originalEvent.detail > 0) ? $(this).width() - turn_num : $(this).width() + turn_num)
          e.stopPropagation()
          return false
        })
      } else {
        body.on('mousewheel', '.vue-html5-editor > .content img', function (e) {
          const turn_num = (shiftDown) ? shift_ball_num : ball_num
          $(this).width((e.originalEvent.wheelDelta > 0) ? $(this).width() + turn_num : $(this).width() - turn_num)
          e.stopPropagation()
          return false
        })
      }
      body.on('mouseenter', '.vue-html5-editor > .content img', function () {
        $(this).parents('.vue-html5-editor > .content').each(function () {
          if ($(this).height() < $(this)[0].scrollHeight) {
            $(this).addClass('hide_overflow_when_hover')
          }
        })
      })
      body.on('mouseleave', '.vue-html5-editor > .content img', function () {
        $(this).parents().removeClass('hide_overflow_when_hover')
      })
    })
  },
  mounted() {
    this.customerToolbar();
  }
}
</script>

<style>
.hide_overflow_when_hover {
  overflow: hidden !important;
}

.vue-html5-editor {
  height: 100%;
}

.vue-html5-editor > .content {
  height: calc(100% - 37px);
}
</style>
<style scoped>
</style>
